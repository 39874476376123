<template>
  <v-container fluid>
    <template>
      <v-breadcrumbs>
        <template>
          <v-breadcrumbs-item>
            <v-icon color="primary">mdi-home</v-icon>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item>
            <v-icon>mdi-chevron-double-right</v-icon> LISTA PETICIONES CLASES
          </v-breadcrumbs-item>
          <v-breadcrumbs-item>
            <v-icon>mdi-chevron-double-right</v-icon>Petición de clases
            <strong class="ml-2"> #{{ requestClass.code }} </strong>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </template>

    <create ref="eventForm" mode="edit"></create>
  </v-container>
</template>

<script>
import { showRequestClass } from "../../services/humansRights";
import Swal from "sweetalert2";
import Create from "./requestClass/Create.vue";
export default {
  name: "EditRequestClass",
  data() {
    return {
      requestClass: {
        id: "",
        name: "",
        code: "",
      },
      eventId: "",
    };
  },
  async mounted() {
    const id = this.$route.params.id;
    this.eventId = id;
    try {
      this.showLoader();
      const eventShowResponse = await showRequestClass(id);
      const requestClass = {
        id: eventShowResponse.data.id,
        name: eventShowResponse.data.name,
        code: eventShowResponse.data.code,
      };
      this.requestClass = requestClass;

      this.$nextTick(() => {
        this.$refs.eventForm.setEventData(requestClass);
      });
      this.hideLoader();
    } catch (err) {
      this.hideLoader();
      console.error(err);
      Swal.fire(
        "Error",
        "ha ocurrido un error al procesar la solicitud",
        "error"
      );
    }
  },
  components: { Create },
};
</script>

<style scoped></style>
